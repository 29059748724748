<template>
  <div>
    <br/><br/>
    <v-form
      ref="form"
      lazy-validation
    >
      <v-row>
        <v-col cols="12">
          <br/>
          <p @click="showModal('reparation-simple-jean')">Quelques exemples avant/après
            <v-icon color="black darken-2" dark small>
              mdi-information
            </v-icon>
          </p>

        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h6>EMPLACEMENTS, DIMENSIONS, QUANTITÉS</h6>
          <p>Indiquez les quantités en fonction des emplacements à l'aide des + et -<br/>
            Plusieurs emplacements et dimensions possibles sur un même vêtement</p>
        </v-col>
      </v-row>
      <div v-if="elements">
        <v-row>
          <v-col cols="12">
            <v-expansion-panels
              v-model="expanded"
            >
              <v-expansion-panel v-for="(element, indexR) in elements" :key="indexR">
                <v-expansion-panel-header>
                  <b>{{ element.emplacement }}</b>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col v-for="(label, index) in element.labels" :key="index" cols="12" sm="3">
                      <table border="0" class="dimensions">
                        <tr>
                          <td>{{ label.label }}</td>
                          <td>
                            <Vminusplusfield
                              :index="index"
                              :indexR="indexR"
                              :max="10"
                              :min="0" :price="label.price"
                              :value="detailQte[indexR][index]"
                              @input="e => getInfos(e, label.bonus)"
                            />
                          </td>
                        </tr>
                      </table>
                    </v-col>
                  </v-row>
                  <template v-if="element.checkbox && checkboxesValues[indexR] !== undefined">
                    <v-row v-for="(checkbox, index) in element.checkbox" :key="checkbox.label">
                      <v-col cols="12" md="6">
                        <v-checkbox
                          :label="checkbox.label"
                          :value="checkboxesValues[indexR][index].checked"
                          @change="(value) => onChangeCheckbox(index, indexR, checkbox.price, value)"
                        />
                      </v-col>
                      <v-col
                        v-if="checkbox.with_quantity && checkboxesValues[indexR][index].checked"
                        class="mt-4 mt-n5 mt-sm-0" cols="12" md="6">
                        <div class="mt-4">
                          {{ checkbox.quantity_label }} :
                          <Vminusplusfield
                            :index="index"
                            :index-r="indexR"
                            :max="2"
                            :min="1"
                            :price="checkbox.price"
                            :value="checkboxesValues[indexR][index].quantity"
                            @input="onChangeCheckboxPlusMinus"
                          />
                        </div>
                      </v-col>
                    </v-row>
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </div>
      <v-row>
        <v-col cols="12">
          <br/><br/>
          <h6>COMMENTAIRE</h6>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model="emplacement"
            name="input-7-4"
            outlined
            placeholder="Ecrivez votre commentaire"
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <image-preview
            ref="image_preview"
            :is-bonus-eligible="totalBonus > 0"
            :value="photo"
            @image="image => photo = image"
          />
        </v-col>
        <price :amount="price" :bonus="totalBonus" :display-bottom-line="totalQty > 0"/>
      </v-row>
      <bonus-image-confirm
        v-if="price > 0 && totalBonus > 0 && !photo"
        @skip-bonus="totalBonus = 0"
        @add-picture="$refs.image_preview.$refs.file.click()"
      />
      <confirm-flow
        v-else-if="price > 0 && !this.flux.indexEditProduit"
        @finalize="noarticle"
        @new-repair="onSelectNewRepair"
        @new-article="noarticle(false)"
      />
      <v-row v-else-if="this.flux.indexEditProduit">
        <v-col v-if="error" cols="12">
          <div class="text-center" style="color:#FF0000">Vous devez sélectionner le nombre de trous par
            dimensions
          </div>
        </v-col>
        <v-col class="text-center" cols="12">
          <div class="btn btn-1 text-center btn-size" @click="noarticle">MODIFIER</div>
        </v-col>
      </v-row>
    </v-form>
    <span style="display:none">{{ flux.actionId }}</span>
  </div>
</template>
<script>
import trouPrice from '../../assets/data/price/trou_jean.json';
import Vminusplusfield from '../interface/PlusMinusField.vue'

import {mapMutations, mapState} from 'vuex'
import Price from "@/components/shared/Price.vue";
import ImagePreview from "@/components/interface/ImagePreview.vue";
import BonusImageConfirm from "@/components/shared/BonusImageConfirm.vue";
import ConfirmFlow from "@/components/shared/ConfirmFlow.vue";

let liste_reparations = []

export default {
  data() {
    return {
      labels: [],
      tabPromotions: [],
      detailQte: [],
      detailPrice: [],
      detailBonus: [],
      totalQty: 0,
      promotion: null,
      price: 0,
      totalBonus: 0,
      emplacement: null,
      elements: [],
      expanded: 0,
      error: false,
      checkboxesValues: [],
      photo: null
    }
  },
  computed: {
    ...mapState('cart-app', ['cards', 'products']),
    ...mapState('flux-app', ['flux']),
  },
  created() {
    if (this.flux.componentName != 'trou-jean' || this.flux.activeTab != 'reparation_simple_jean') {
      return null;
    }
    this.elements = trouPrice
    this.tabPromotions = trouPrice[0].promotions

    this.elements.forEach((element, i) => {
      this.detailQte.push([])
      this.detailPrice.push([])
      this.detailBonus.push([])

      element.labels.forEach(() => {
        this.detailQte[i].push(0)
        this.detailPrice[i].push(0)
        this.detailBonus[i].push(0)
      })
    })

    if (this.flux.level2 && this.flux.verrou === false && this.flux.indexEditProduit !== null) {
      this.setVerrou(true)
      this.emplacement = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['emplacement']
      this.detailQte = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['details'][0]
      this.price = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['price']
    }

    this.elements.forEach((element, i) => {
      this.checkboxesValues[i] = []
      ;(element.checkbox || []).forEach((checkbox, y) => {
        this.$set(this.checkboxesValues[i], y, {
          ...checkbox,
          checked: false,
          quantity: 0
        })
      })
    })
  },
  updated() {

    if (this.flux.componentName != 'trou-jean' || this.flux.activeTab != 'reparation_simple_jean') {
      return null;
    }

    if (this.flux.level2) {
      this.elements = trouPrice
      this.tabPromotions = trouPrice[0].promotions
    }

    if (this.flux.level2 && this.flux.verrou === false && this.flux.indexEditProduit !== null) {
      this.setVerrou(true)
      this.detailQte = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['details'][0]
      this.detailPrice = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['details'][1]
      this.price = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['price']
      this.totalBonus = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['bonus']
      this.emplacement = this.products[this.flux.indexEditProduit]['reparations'][this.flux.indexEditReparation]['emplacement']
    }
  },
  methods: {
    ...mapMutations('flux-app', ['setConstat', 'setcptProduct', 'setcptReparation', 'setVerrou', 'setActiveTab', 'setLevel1', 'setLevel2', 'setLevel3', 'setComponentName']),
    ...mapMutations('cart-app', ['addProduct', 'getTotalCart']),
    noarticle(showCart = true) {
      if (this.price <= 0) {
        this.error = true;
      } else if (this.$refs.form.validate()) {
        if (this.flux.indexEditProduit !== null) { //Mode Edit
          this.editProduct();
          this.setLevel1(null)
          this.setLevel2(null)
          this.setLevel3(null)
          this.setComponentName(null)
        } else {
          this.storeProduct()
          this.setcptProduct(this.flux.cptProduct + 1)
          liste_reparations = []
          this.setcptReparation(0)
        }

        this.$emit('evenement', 'scroll1')

        if (showCart) {
          this.setConstat('cart')
          this.$emit('showmodal', 'cart')
        }
      }
    },
    onSelectNewRepair() {
      this.storeProduct()
      this.$emit('evenement', 'scroll3')
    },
    editProduct() {
      liste_reparations = this.products[this.flux.indexEditProduit].reparations
      //On check l'historique des réparations
      let index = liste_reparations.findIndex((obj => obj.id == (this.flux.indexEditReparation + 1)));
      if (index > -1) {
        let reparation = {
          id: liste_reparations[index].id,
          activeTab: 'reparation_simple_jean',
          type: 'Réparation simple jean',
          componentName: 'trou-jean',
          emplacement: this.emplacement,
          isCursor: this.isCursor,
          qte: 1,
          price: this.price,
          bonus: this.photo ? this.totalBonus : 0,
          photo: this.photo,
          details: [
            this.detailQte,
            this.detailPrice
          ]
        }

        this.products[this.flux.indexEditProduit].reparations.splice(index, 1, reparation)
        let data = {
          idLevel1: this.products[this.flux.indexEditProduit].idLevel1,
          level1: this.products[this.flux.indexEditProduit].level1,
          level2: this.products[this.flux.indexEditProduit].level2,
          level3: this.products[this.flux.indexEditProduit].level3,
          isDouble: this.products[this.flux.indexEditProduit].isDouble,
          id: this.products[this.flux.indexEditProduit].id,
          name: this.products[this.flux.indexEditProduit].name,
          famille: this.products[this.flux.indexEditProduit].famille,
          reparations: liste_reparations
        }


        this.addProduct(data)
        this.getTotalCart(this.products)
        this.$emit('evenement', 'scroll3')

      }
    },
    storeProduct() {
      if (this.price <= 0) {
        this.error = true;
      } else if (this.$refs.form.validate()) {
        this.setcptReparation(this.flux.cptReparation + 1)
        this.setActiveTab('reparation_simple_jean')
        if (this.flux.cptProduct < 1) {
          this.setcptProduct(this.flux.cptProduct + 1)
        }

        //On check l'historique des réparations
        liste_reparations = []
        let index = this.products.findIndex((obj => obj.id == this.flux.cptProduct));
        if (index > -1) {
          liste_reparations = this.products[index].reparations
        }


        //let reparation = {}
        let qteTotal = 0
        let priceTotal = 0

        for (let i = 0; i < this.detailPrice.length; i++) {
          for (let j = 0; j < this.detailPrice[i].length; j++) {
            if (this.detailPrice[i][j] > 0) {
              qteTotal = qteTotal + this.detailQte[i][j]
              priceTotal = priceTotal + this.detailPrice[i][j]
            }
          }
        }

        const details = [
          this.detailQte,
          this.detailPrice
        ]
        const elements = this.elements.map(element => ({
          emplacement: element.emplacement,
          labels: element.labels.map(label => label.label)
        }))
        const precisions = this.checkboxesValues.map(indexR => indexR.filter(checkbox => checkbox.checked && checkbox.quantity > 0))
        precisions.forEach((precision, i) => {
          if (precision.length === 0) {
            return
          }

          precision.forEach((element) => {
            elements[i].labels.push(element.label)
            details[0][i].push(element.quantity)
            details[1][i].push(element?.prices[element.quantity] ?? (element.price * element.quantity))
          })
        })

        liste_reparations.push({
          id: this.flux.cptReparation,
          activeTab: 'reparation_simple_jean',
          type: this.flux.level3,
          componentName: 'trou-jean',
          emplacement: this.emplacement,
          isCursor: this.isCursor,
          qte: 1,
          price: this.price,
          bonus: this.photo ? this.totalBonus : 0,
          photo: this.photo,
          details,
          elements,
          precisions
        })

        let data = {
          idLevel1: this.flux.idLevel1,
          level1: this.flux.level1,
          level2: this.flux.level2,
          level3: this.flux.level3,
          isDouble: this.flux.isDouble,
          id: this.flux.cptProduct,
          name: this.flux.level2,
          famille: this.flux.level1,
          reparations: liste_reparations
        }
        this.addProduct(data)
        this.getTotalCart(this.products)
        this.setLevel3(null)
        this.setComponentName(null)
      }
    },
    resetPrice() {
      this.price = 0
    },
    showModal(constat) {
      this.setConstat(constat)
      this.$emit('showmodal')
    },
    getInfos(value, bonus) {
      this.totalQty = 0
      this.promotion = 0

      this.$set(this.detailQte[value[3]], value[0], value[1])
      this.$set(this.detailPrice[value[3]], value[0], value[2])
      this.$set(this.detailPrice[value[3]], value[0], Math.round((this.flux.isDouble ? (value[2] * 1.2) : value[2]) * 100) / 100)
      this.$set(this.detailBonus[value[3]], value[0], (bonus ?? 0) * value[1])

      for (let i = 0; i < this.detailQte.length; i++) {
        for (let j = 0; j < this.detailQte[i].length; j++) {
          this.totalQty = this.totalQty + this.detailQte[i][j]
        }
      }

      for (let j = 0; j < this.tabPromotions?.length ?? 0; j++) {
        if (this.totalQty > 1 && this.totalQty < this.tabPromotions[j].qtemax) {
          this.promotion = this.tabPromotions[j].remise
          break;
        }
      }
      this.getPrice()
    },
    getPrice() {
      this.price = 0
      this.totalBonus = 0
      for (let i = 0; i < this.detailPrice.length; i++) {
        for (let j = 0; j < this.detailPrice[i].length; j++) {
          this.price += this.detailPrice[i][j]
          this.totalBonus += this.detailBonus[i][j]
        }
      }

      this.tmpPrice = this.price * (1 - (this.promotion / 100))

      this.price = Math.round(this.tmpPrice * 100) / 100

      this.checkboxesValues.forEach((checkboxes) => {
        (checkboxes || []).forEach(checkbox => {
          this.price += (checkbox.prices?.[checkbox.quantity] ?? (checkbox.price * checkbox.quantity))
          this.totalBonus += (checkbox.bonus?.[checkbox.quantity] ?? (checkbox.price * checkbox.quantity))
        })
      })
      this.error = false
    },
    onChangeCheckbox(index, indexR, unit_price, value) {
      this.checkboxesValues[indexR][index].checked = !!value

      if (this.checkboxesValues[indexR][index].checked === true && this.checkboxesValues[indexR][index].quantity < 1) {
        this.checkboxesValues[indexR][index].quantity = 1
      }
      if (this.checkboxesValues[indexR][index].checked === false) {
        this.checkboxesValues[indexR][index].quantity = 0
      }

      this.getPrice()
    },
    onChangeCheckboxPlusMinus(value) {
      this.checkboxesValues[value[3]][value[0]].quantity = value[1]

      this.getPrice()
    }
  },
  components: {
    ConfirmFlow,
    BonusImageConfirm,
    ImagePreview,
    Price,
    Vminusplusfield
  }
}
</script>
<style scoped>
table.dimensions td {
  min-width: 65px !important;
}
</style>
