<template>
  <v-row>
    <v-col cols="12">
      <div class="text-center">
        <b>La photo est obligatoire pour bénéficier du bonus. J'ajoute une photo pour en bénéficier.</b>
      </div>
    </v-col>
    <v-col class="text-center" cols="12" sm="6">
      <div class="btn btn-light text-center btn-size" @click="$emit('skip-bonus')">
        Je continue sans bénéficier du bonus
      </div>
    </v-col>
    <v-col class="text-center" cols="12" sm="6">
      <div class="btn btn-1 text-center btn-size" @click="$emit('add-picture')">
        OUI
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "BonusImageConfirm"
}
</script>

<style scoped>

</style>
